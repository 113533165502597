import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import Banner from '../components/Banner'

import gruntboatcleaner from '../assets/images/gruntboatcleaner.jpg'
import commercialliens from '../assets/images/commercialliens.jpg'
import smoothinvoices from '../assets/images/smoothinvoices.jpg'
import smoothinvoicesapp from '../assets/images/smoothinvoices-app.jpg'
import shandonhotel from '../assets/images/shandonhotel.jpg'
import browns from '../assets/images/browns.jpg'
import ardtara from '../assets/images/ardtara.jpg'
import leadphotography from '../assets/images/leadphotography.jpg'
import stateside from '../assets/images/stateside.jpg'
import favicon from '../assets/images/website-icon.png';

class HomeIndex extends React.Component {
  render() {

    return (
      <Layout>

        <Helmet
          title="Paddy Hegarty - Portfolio Website"
          meta={[
            { name: 'description', content: 'Portfolio website for Paddy Hegarty, giving an overview of skills, projects and career' },
            { name: 'keywords', content: 'website developer, react developer' },
            { name: 'robots', content: 'noindex' }
          ]}
          link={[
            { rel: 'shortcut icon', type: 'image/png', href: `${favicon}` }
          ]}
        >
        </Helmet>

        <Banner />

        <div id="main">
          <section id="one">
            <div className="inner">
              <header>
                <h2>Website Developer - React, Front End Developent, WordPress Etc...</h2>
              </header>
              Current Position:<h3>Senior Web Developer at Softtek</h3>
              <p>Previously I had worked at Simply Zesty as a Front End Developer. Before that I worked for 16 years as a freelance website developer where I provided bespoke websites  to SMEs. Excellent attention to detail with ability to convey advanced solutions in an easy to understand format and communicate effectively and persuasively to decision makers. Able to manage multiple assignments while meeting deadlines and consistently building strong relationships with clients and other stakeholders</p>
              <p><b>React js, Frontend Development, Javascript, Jquery, HTML5, CSS3, Rest APIs, SCSS, Wordpress, Adobe Photoshop, Adobe Illustrator, Adobe Indesign</b></p>
              <p><a href="/about" className="button">More Details</a></p>
            </div>
          </section>
          <section id="portfolio" className="spotlights">
            <section>
              <a href="https://www.smoothinvoices.com/" target="_blank" rel="noreferrer" className="image">
                <img src={smoothinvoices} alt="Smooth Invoices" />
              </a>
              <div className="content">
                <div className="inner">
                  <header className="major">
                    <h3>Smooth Invoices</h3>
                  </header>
                  <p><b>Static Website</b> - Landing page for Smooth Invoices SAAS application that provides an easy way of creating invoices. The site is a simple pure HTML5 website. Its fully responsive, uses jquery and some librarys to create some elements.</p>
                  <ul className="actions">
                    <li><a href="https://www.smoothinvoices.com/" target="_blank" rel="noreferrer" className="button">View Website</a></li>
                  </ul>
                </div>
              </div>
            </section>
            <section>
              <a href="https://app.smoothinvoices.com/" target="_blank" rel="noreferrer" className="image">
                <img src={smoothinvoicesapp} alt="Smooth Invoices App" />
              </a>
              <div className="content">
                <div className="inner">
                  <header className="major">
                    <h3>Smooth Invoices</h3>
                  </header>
                  <p><b>Full Stack Application</b> This is a cloud based full stack SAAS application created using React JS with a Node / Express backend and MongoDb database. It has many features that allows you to create and manage your invoices. Its deployed on an Ubuntu server using Nginx as a reverse proxy.</p>
                  <ul className="actions">
                    <li><a href="https://app.smoothinvoices.com/" target="_blank" rel="noreferrer" className="button">View Project</a></li>
                  </ul>
                </div>
              </div>
            </section>
            <section>
              <a href="https://www.shandonhotelspa.com/" target="_blank" rel="noreferrer" className="image">
                <img src={shandonhotel} alt="Shandon Hotel Spa" />
              </a>
              <div className="content">
                <div className="inner">
                  <header className="major">
                    <h3>Shandon Hotel and Spa</h3>
                  </header>
                  <p>This hotel in Donegal has a beautiful setting with amazing views. Its a very popular location and the website is extremly busy. This is a custom theme built from scratch. The desktop design(PSD) was provided by a designer. Many other features are included eg. woocommerce, automatic gift vouchers and virtual tours</p>
                  <ul className="actions">
                    <li><a href="https://www.shandonhotelspa.com/" target="_blank" rel="noreferrer" className="button">View Website</a></li>
                  </ul>
                </div>
              </div>
            </section>
            <section>
              <a href="https://www.brownsrestaurant.com" target="_blank" rel="noreferrer" className="image">
                <img src={browns} alt="Browns Restaurant Group" />
              </a>
              <div className="content">
                <div className="inner">
                  <header className="major">
                    <h3>Browns Restaurants Group</h3>
                  </header>
                  <p>This restaurant group required a number of websites for their chain of fine dining restaurants. Along with some great images, features include ecommerce and online booking. Standard themes were used and were modified to suit the needs of the clients.</p>
                  <ul className="actions">
                    <li><a href="https://www.brownsrestaurant.com" target="_blank" rel="noreferrer" className="button">View Website</a></li>
                  </ul>
                </div>
              </div>
            </section>
            <section>
              <a href="https://gruntboatcleaner.co.uk/" target="_blank" rel="noreferrer" className="image">
                <img src={gruntboatcleaner} alt="Grunt Boat Cleaner" />
              </a>
              <div className="content">
                <div className="inner">
                  <header className="major">
                    <h3>Grunt Boat Cleaner</h3>
                  </header>
                  <p>Grunt is one of the best boat cleaning products on the market. This is an ecommerce WordPress website with a standard theme that needed many modifications to match the requirements of the client</p>
                  <ul className="actions">
                    <li><a href="https://gruntboatcleaner.co.uk/" target="_blank" rel="noreferrer" className="button">View Website</a></li>
                  </ul>
                </div>
              </div>
            </section>
            <section>
              <a href="https://www.ardtara.com" target="_blank" rel="noreferrer" className="image">
                <img src={ardtara} alt="Ardtara Country House" />
              </a>
              <div className="content">
                <div className="inner">
                  <header className="major">
                    <h3>Artara Country Hotel</h3>
                  </header>
                  <p>Ardtara is a beautiful country house with luxurious accommodation and coupled with delicious fine dining they provide an amazing experience. This is a custom WordPress theme built from scratch. The design was provided in pdf format for desktop only.</p>
                  <ul className="actions">
                    <li><a href="https://www.ardtara.com" target="_blank" rel="noreferrer" className="button">View Website</a></li>
                  </ul>
                </div>
              </div>
            </section>
            <section>
              <a href="https://www.stateside.ie" target="_blank" rel="noreferrer" className="image">
                <img src={stateside} alt="Stateside American Restaurant" />
              </a>
              <div className="content">
                <div className="inner">
                  <header className="major">
                    <h3>Stateside Restaurant</h3>
                  </header>
                  <p>Stateside is a large american themed restaurant providing amazing food. This WordPress website has a basic theme that was modified to suit the image of the restaurant and improve responsiveness</p>
                  <ul className="actions">
                    <li><a href="https://www.stateside.ie" target="_blank" rel="noreferrer" className="button">View Website</a></li>
                  </ul>
                </div>
              </div>
            </section>
          </section>


        </div>

      </Layout>
    )
  }
}

export default HomeIndex